import $ from "jquery";
var tag = document.createElement("script");
const isYoutubeNeeded = document.getElementById("sliderplayer1");
if (isYoutubeNeeded) {
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  var player;
  const ids = [
    "-ltBDd0BOho",
    "FEYwP2T0FTU",
    "ExrjOYB5O7w",
    "tvhmIAgxiww",
    "UQrYMJbJdMo",
    "sfyF5hnZYHA"
  ];
  window.onYouTubeIframeAPIReady = () => {
    const players = ids.map((id, index) => {
      return new YT.Player(`sliderplayer${index}`, {
        videoId: id,
        playerVars: { controls: 0, showinfo: 0 },
        events: {
          onReady: onSliderPlayerReady
        }
      });
    });
    document.querySelectorAll(".carousel").forEach((element, index) => {
      $(`#videocarousel${index}`).on("slide.bs.carousel", function(e) {
        players.map(p => {
          if (typeof p.stopVideo !== "undefined") {
            p.stopVideo();
          }
        });
        [...e.currentTarget.querySelectorAll(".carousel-caption")].map(item => {
          item.classList.add("shown");
          item.classList.remove("pointer-events-none");
        });
        [...e.currentTarget.querySelectorAll(".videocontainer")].map(item => {
          item.classList.remove("shown");
          item.classList.add("pointer-events-none");
        });
      });
    });
  };
  const onSliderPlayerReady = event => {
    const button = document.getElementById(
      `${event.target.a.getAttribute("id")}button`
    );
    button.addEventListener("click", function(clickEvent) {
      const caption = clickEvent.currentTarget.parentElement.parentElement.querySelector(
        ".carousel-caption"
      );
      caption.classList.remove("shown");
      caption.classList.add("pointer-events-none");
      const container = clickEvent.currentTarget.parentElement.parentElement.querySelector(
        ".videocontainer"
      );
      container.classList.add("shown");
      container.classList.remove("pointer-events-none");
      event.target.playVideo();
    });
  };
}
