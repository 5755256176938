/* global, validate */
import $ from 'jquery'
import '../../css/index.sass';
import './count-numbers';
import './canvas';
import './youtubeslider';
import './validation';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';


if (document.getElementById('carouselmorebutton')) {
  document.getElementById('carouselmorebutton').addEventListener('click', () => {
    $('#carouselExampleIndicators').carousel('next')
    // document.getElementById('carouselmorebutton').classList.add('fading')

  })
}
if (document.getElementById('already_subscribed') !== null && localStorage.getItem('hartzbreaker_newsletter')) {
document.getElementById('already_subscribed').querySelector("span").innerText = localStorage.getItem('hartzbreaker_newsletter')
  document.getElementById('already_subscribed').classList.remove("d-none")
}
$('#nl_form').on('submit', function(e) {
  e.preventDefault();
  if (this.elements["age"].value) {
    return
  }
  var payload = {
    "email": this.elements["EMAIL"].value,
    "age": this.elements["age"].value
  }
  document.getElementById("nl_btn_text").classList.add("d-none")
  document.getElementById("nl_btn_icon").classList.remove("d-none")
  document.getElementById("nl_btn_icon").classList.add("d-block")
  document.getElementById("nl_form").querySelector(".inputs").classList.add("d-none")

  document.querySelector("#newsletter-button").disabled = true
  fetch("/newsletter", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json"
      }
    }).then(response => response.json())
    .then(response => {
      document.getElementById("nl_btn_text").classList.remove("d-none")
      document.getElementById("nl_btn_icon").classList.add("d-none")
      document.getElementById("nl_btn_icon").classList.remove("d-block")
      var status = JSON.parse(response.status)
      if (status.euid !== undefined) {
        var text = "Juhu - du hast dich für unseren Newsletter registriert! Jetzt musst du nur noch den Bestätigungslink klicken, den wir dir an deine E-Mail-Adresse gesendet haben"
        localStorage.setItem('hartzbreaker_newsletter', payload.email)
        document.getElementById('already_subscribed').querySelector("span").innerText = payload.email
      } else if(status.code === 214) {
        document.getElementById("nl_form").querySelector(".inputs").classList.remove("d-none")
        document.querySelector("#newsletter-button").disabled = false

        var text = "Diese E-Mail-Adresse steht bereits auf unserer Liste."
      } else {
        document.getElementById("nl_form").querySelector(".inputs").classList.remove("d-none")
        document.querySelector("#newsletter-button").disabled = false

        var text = "Oh - es scheint etwas schief gegangen zu sein."
      }
      document.getElementById("response").classList.remove("d-none")
      document.getElementById("response").innerText = text
    });
})
$('.collapse.faq').on('hide.bs.collapse', function() {
  this.parentElement.querySelector(".faq-closed").classList.add("hidden")
  this.parentElement.querySelector(".faq-open").classList.remove("hidden")

})
$('.collapse.faq').on('show.bs.collapse', function() {
  this.parentElement.querySelector(".faq-closed").classList.remove("hidden")
  this.parentElement.querySelector(".faq-open").classList.add("hidden")
})

// for (var value of document.querySelectorAll("header a, footer a")) {
//   value.addEventListener("click", item => {
//     document.querySelector("body").classList.add("fade")
//     let svg = document.getElementById("svg");
//     svg.setAttribute("style", `left: ${event.clientX}px; top: ${event.clientY}px;`);
//
//     var delay = 50
//     let circle = svg.querySelector("circle")
//     for (let i = 0; i < 100; i++) {
//       setTimeout(function() {
//         circle.setAttribute("r", i);
//       }, delay += 3)
//     }
//   })
// }

const debitFormButton = document.getElementById("scrollToDebitDonation")
if (debitFormButton) {
  debitFormButton.addEventListener("click", event => {
    event.preventDefault();
    document.getElementById("scrollToDebitDonation").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  });
}
var scrollbuttons = document.getElementsByClassName('scrollbutton');
for (var i = 0, len = scrollbuttons.length; i < len; i++) {
  scrollbuttons[i].addEventListener('click', function (event) {
    event.preventDefault()
    document.querySelector(event.target.attributes.href.value).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
  })
}
var numbers = [...document.querySelectorAll("g[id$='x25_']")]
function switchElements(elements){
  elements.forEach(n => n.style.display = "none");
  elements.push(elements.shift());
  elements[0].style.display = "block";
  if(Date.now()  % 2 === 0){
    //console.log("Every second time");
  }
  setTimeout(function(){
    switchElements(elements)
  }, 1000);
}
if(numbers.length > 0){
  switchElements(numbers)
}

var numbers2 = [...document.querySelectorAll("g[id$='x2F_250']")]
function switchElements2(elements){
  elements.forEach(n => n.style.display = "none");
  elements.push(elements.shift());
  elements[0].style.display = "block";

  setTimeout(function(){
    switchElements2(elements)
  }, 500);
}
if(numbers2.length > 0) {
  switchElements2(numbers2)
}


var elems = document.getElementsByClassName('confirmation');
var confirmIt = function (e) {
  if (!confirm('Bist du dir sicher? Wir werden dich sehr vermissen!')) e.preventDefault();
};
for (var i = 0, l = elems.length; i < l; i++) {
  elems[i].addEventListener('click', confirmIt, false);
}
//const throttle = (func, limit) => {
  //let lastFunc
  //let lastRan
  //return function() {
    //const context = this
    //const args = arguments
    //if (!lastRan) {
      //func.apply(context, args)
      //lastRan = Date.now()
    //} else {
      //clearTimeout(lastFunc)
      //lastFunc = setTimeout(function() {
        //if ((Date.now() - lastRan) >= limit) {
          //func.apply(context, args)
          //lastRan = Date.now()
        //}
      //}, limit - (Date.now() - lastRan))
    //}
  //}
//}

//window.onscroll = throttle(function() {myFunction()}, 10);

//var floatings = [...document.querySelectorAll(".floatings")]
//function myFunction() {
  //floatings.forEach((item) => {
    //if(elementInViewport2(item) && window.innerWidth > 800){
      //let transform = ( window.pageYOffset - item.parentElement.offsetTop + ( item.height / 2 ) ) / 12
      //item.style.transform = `translateY(${transform}px)`
    //}
  //})
//}
//function elementInViewport2(el) {
  //var top = el.offsetTop;
  //var left = el.offsetLeft;
  //var width = el.offsetWidth;
  //var height = el.offsetHeight;

  //while(el.offsetParent) {
    //el = el.offsetParent;
    //top += el.offsetTop;
    //left += el.offsetLeft;
  //}

  //return (
    //top < (window.pageYOffset + window.innerHeight) &&
    //left < (window.pageXOffset + window.innerWidth) &&
    //(top + height) > window.pageYOffset &&
    //(left + width) > window.pageXOffset
  //);
//}
