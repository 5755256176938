const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
setNumber = (item, i, max) => {
  const next = Math.floor(i - i / 100);
  const delay = 20 * (next / max);
  if (i > 0) {
    setTimeout(() => {
      item.innerHTML = max - next;
      setNumber(item, next, max);
    }, delay);
  }
};
const countUp = ELEMENTS => {
  ELEMENTS.forEach(e => {
    if (elementInViewport(e)) {
      if (e.classList.contains("counted")) return;
      e.classList.add("counted");
      const number = parseInt(e.dataset.number);
      setNumber(e, number, number);
    }
  });
};

function elementInViewport(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight - window.innerHeight / 5 &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}
window.onscroll = throttle(function() {
  const ELEMENTS = document.querySelectorAll(".countNumber");
  if (ELEMENTS.length > 0) {
    countUp(ELEMENTS);
  }
}, 10);
